export default class Header {
    init(root) {
        this.root = root
        // console.log('this.root')
        // console.log(this.root)
        this.bodyElement = document.querySelector('body')
        this.headerLinks = document.querySelectorAll('.header a')
        this.burger = document.querySelector('.header-burger-menu');
        this.burger.addEventListener('click', () => {
          this.toggleMenu()
        })

        this.headerLinks.forEach(link => {
          link.addEventListener('click', () => {
            this.close()
          })
        })
    }

    toggleMenu() {
      this.burger.classList.toggle('is-open')
      this.root.classList.toggle('is-hidden')
      this.bodyElement.classList.toggle('burger-menu-visible')
    }
    close() {
      this.burger.classList.remove('is-open')
      this.root.classList.add('is-hidden')
      this.bodyElement.classList.remove('burger-menu-visible')
    }
}
