import gsap from 'gsap';

export default class UpdateUI
{
  constructor(settings)
  {
    // Setup
    this.settings = settings;

    //HTLM Elements
    this.bodyElement = document.querySelector('body');
    this.topicTitleLine1 = document.querySelector('.topic-title-line1');
    this.topicTitleLine2 = document.querySelector('.topic-title-line2');
    this.topicTitleLine3 = document.querySelector('.topic-title-line3');
    this.jumpLinkLabel = document.querySelector('.jumplink-label');
    this.rootElement = document.documentElement;
  }

  setActive() {
    this.bodyElement.style.backgroundColor = this.settings.backgroundColor
    // this.topicTitleLine1.innerHTML = this.settings.title[0]
    // this.topicTitleLine2.innerHTML = this.settings.title[1]
    this.switchTitle(this.topicTitleLine1, this.settings.title[0], 0.1)
    this.switchTitle(this.topicTitleLine2, this.settings.title[1])
    let line3 = " ";
    if(this.settings.title[2]) {
      line3 = this.settings.title[2];
    }
    this.switchTitle(this.topicTitleLine3, line3)
    this.jumpLinkLabel.innerHTML = this.settings.title[0] + ' ' + this.settings.title[1]
    if(this.settings.foreground === "light") {
      this.rootElement.style.setProperty('--local-foreground', 'white');
      this.rootElement.style.setProperty('--local-background', 'black');
      document.querySelector('.footer-logos').classList.add('is-white');
    } else {
      this.rootElement.style.setProperty('--local-foreground', 'black');
      this.rootElement.style.setProperty('--local-background', 'white');
      document.querySelector('.footer-logos').classList.remove('is-white');
    }
    this.filterArticleTeasers(this.settings.slug)
  }

  filterArticleTeasers(slug) {
    document.querySelectorAll('.topic-wrapper').forEach(list => {
      list.style.display = "none";
      if(list.classList.contains('topic-' + slug)) {
        list.style.display = "block";
      }
    })
  }

  switchTitle(wrapper, title, delay = 0) {
    let oldLines = wrapper.querySelectorAll('.old-line, .new-line');

    const div = document.createElement('div');
    div.classList.add('line')
    div.classList.add('new-line')
    div.innerHTML = `${title}`
    wrapper.appendChild(div);

    gsap.from(div, {
      y: "100%",
      duration: 0.625,
      delay: delay + 0.625,
      onComplete: () => {
        div.classList.remove('new-line')
        div.classList.add('old-line')
      }
    })

    if(oldLines) {
      gsap.to(oldLines, {
        y: "100%",
        duration: 0.625,
        delay: delay,
        onComplete: () => {
          oldLines.forEach(line => {
            line.remove()
          })
        }
      })
    }
  }
}



