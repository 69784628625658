const siteurl = document.body.dataset.siteurl

export default [
    {
      name: `holzMatcap`,
      type: `texture`,
      path: `${siteurl}/assets/textures/holz-matcap.png`
    },
    {
      name: `metallMatcap`,
      type: `texture`,
      path: `${siteurl}/assets/textures/metall-matcap.png`
    },
    {
      name: `plasticMatcap`,
      type: `texture`,
      path: `${siteurl}/assets/textures/plastic-matcap.png`
    },
    {
      name: `rasenMatcap`,
      type: `texture`,
      path: `${siteurl}/assets/textures/rasen-matcap.png`
    },
    {
      name: `sandMatcap`,
      type: `texture`,
      path: `${siteurl}/assets/textures/sand-matcap.png`
    },
    {
        name: `greenMatcap`,
        type: `texture`,
        path: `${siteurl}/assets/textures/green-matcap.png`
    },
    {
      name: `roofMatcap`,
      type: `texture`,
      path: `${siteurl}/assets/textures/roof-matcap.png`
    },
    {
      name: `wandMatcap`,
      type: `texture`,
      path: `${siteurl}/assets/textures/wand-matcap.png`
    },
    {
        name: `controllingTexture01`,
        type: `texture`,
        path: `${siteurl}/assets/textures/controlling-texture-01.png`
    },
    {
        name: `controllingTexture02`,
        type: `texture`,
        path: `${siteurl}/assets/textures/controlling-texture-02.png`
    },
    {
        name: `basicHouseModel`,
        type: `gltfModel`,
        path: `${siteurl}/assets/models/House/wohnen-026.gltf`
    },
    {
        name: `okonomieModel`,
        type: `gltfModel`,
        path: `${siteurl}/assets/models/Okonomie/oekonomie015.gltf`
    },
    {
        name: `mobilityModel`,
        type: `gltfModel`,
        path: `${siteurl}/assets/models/Mobility/04_mobilitat.gltf`
    },
    {
        name: `energyModel`,
        type: `gltfModel`,
        path: `${siteurl}/assets/models/Ressources/house-energy02.gltf`
    },
    {
        name: `districtHouseModel`,
        type: `gltfModel`,
        path: `${siteurl}/assets/models/District/district-house-lowpoly.gltf`
    },
    {
        name: `neighborhoodModel`,
        type: `gltfModel`,
        path: `${siteurl}/assets/models/District/06_nachbarschaft.gltf`
    }
]
