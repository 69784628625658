import Time from './../../js/Experience/Utils/Time.js'
import Sizes from './../../js/Experience/Utils/Sizes.js'
import { gsap } from "gsap";
import { doc, cursor, scroll, getPosition, inViewport, registeredLoops, registeredResizeFunctions, touchDevice, lenisScrollTo, addGlobalEventListener} from './../../js/utils/events.js';
import { map, clamp, spanFerkel } from './../../js/utils/math.js';

export default class Article {
    init(root) {
      this.root = root
      this.time = new Time()
      this.sizes = new Sizes()

      this.potentiale = this.root.querySelector('#potentiale')
      this.quotes = this.root.querySelectorAll('.quote-text')
      if(this.quotes.length > 0) {
        this.quotes.forEach(quote => {
          quote.innerHTML = spanFerkel(quote.innerHTML)
        })
      }
      this.keyfigures = this.root.querySelectorAll('.keyfigure-title')
      if(this.keyfigures.length > 0) {
        this.keyfigures.forEach(figure => {
          figure.innerHTML = spanFerkel(figure.innerHTML)
        })
      }

      this.watchList = []
      this.referenceElements()

      this.stickyImages = this.root.querySelectorAll('.column-6 .single-image')
      this.setStickyPositions()

      this.initVideos()

      //resize
      this.sizes.on('resize', () => {
        this.watchList = []
        this.referenceElements()
        this.setStickyPositions()
      })

      // Time tick event
      this.time.on('tick', () => {
        this.update()
      })
    }

    initVideos() {
      this.videos = this.root.querySelectorAll('.video-container')

      this.videos.forEach(videoContainer => {
        const loadButton = videoContainer.querySelector('.load-video');
        const videoContent = videoContainer.querySelector('.video-content');

        loadButton.addEventListener('click', function() {
          const videoCode = videoContent.getAttribute('data-code');
          videoContent.innerHTML = videoCode;
          videoContent.style.display = 'block';
        });
      })
    }

    setStickyPositions() {
      this.stickyImages.forEach(el => {
        el.style.top = doc.h / 2 - (el.offsetHeight / 2) + "px"
      })
    }

    referenceElements() {
      this.quotes = this.root.querySelectorAll('.quote-text')
      if(this.quotes.length > 0) {
        this.addToWatchList(this.quotes, 'quote')
      }

      if(this.keyfigures.length > 0) {
        this.addToWatchList(this.keyfigures, 'keyfigure')
      }

      if(this.potentiale) {
        this.addToWatchList([this.potentiale], 'potentiale')
      }
    }

    addToWatchList(list, slug = null) {
      list.forEach(el => {
        let pos = getPosition(el)
        if(slug === 'quote') {
          let spans = el.querySelectorAll('span');
          let spanAnimation = gsap.timeline({
            paused: true
          })
          spanAnimation.fromTo(spans, {
            opacity: 0.2,
          }, {
            opacity: 1,
            duration: 1,
            stagger: 0.05
          })
          this.watchList.push({element: el, position: pos, slug: slug, animation: spanAnimation})
        } else if(slug === 'keyfigure') {
          let spanAnimation = gsap.fromTo(el.querySelectorAll('span'), {
            y: '100%',
          }, {
            y: 0,
            duration: 0.5,
            stagger: 0.05
          })
          this.watchList.push({element: el, position: pos, slug: slug, animation: spanAnimation})
        } else {
          this.watchList.push({element: el, position: pos, slug: slug})
        }
      })
    }

    destroy() {
      this.watchList = [];
      this.time.pause()
    }

    update() {
      this.watchList.forEach(item => {
        let position = inViewport(item.position);
        if(item.slug === 'quote') {
          // item.element.style.opacity = clamp(position.progress)
          item.animation.progress(clamp(map(position.progress, 0, 0.75, 0, 1)))
        } else if(item.slug === 'keyfigure') {
          if(position.progress > 0.25) {
            item.animation.play()
          } else {
            item.animation.reverse()
          }
        } else if(item.slug === 'potentiale') {
          if(position.progress > 0.5) {
            this.root.style.backgroundColor = "#FFF170"
          } else {
            this.root.style.backgroundColor = "#fff"
          }
        }
      })
    }
}
