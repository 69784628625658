import EventEmitter from './EventEmitter.js'

export default class Time extends EventEmitter {
  constructor() {
    super()

    // Setup
    this.start = Date.now()
    this.current = this.start
    this.elapsed = 0
    this.delta = 16
    this.paused = false // Flag to track if the loop is paused

    window.requestAnimationFrame(() => {
      this.tick()
    })
  }

  tick() {
    if (!this.paused) { // Only execute the loop if not paused
      const currentTime = Date.now()
      this.delta = currentTime - this.current
      this.current = currentTime
      this.elapsed = this.current - this.start

      this.trigger('tick')
    }

    window.requestAnimationFrame(() => {
      this.tick()
    })
  }

  pause() {
    this.paused = true
    // console.log('pausing time')
  }

  resume() {
    console.log('resume time')
    this.paused = false
    this.current = Date.now() // Reset the current time to avoid a sudden jump in delta
    this.tick() // Start the loop again
  }
}
