import * as THREE from 'three'
import Experience from '../Experience.js'
import AnimateInOut from '../Utils/AnimateInOut.js'
import UpdateUI from '../Utils/UpdateUI.js'
import PointsOfInterest from '../Utils/PointsOfInterest.js'

export default class Mobility
{
    constructor()
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.camera = this.experience.camera
        this.resources = this.experience.resources
        this.time = this.experience.time
        this.debug = this.experience.debug

        this.sceneIsActive = false;


        //HTLM Elements
        this.bodyElement = document.querySelector('body');
        this.topicTitle = document.querySelector('.topic-title');
        this.jumpLinkLabel = document.querySelector('.jumplink-label');

        // Debug
        if(this.debug.active)
        {
            this.debugFolder = this.debug.ui.addFolder('mobility')
        }

        // Resource
        this.resource = this.resources.items.mobilityModel

        this.setModel()
        this.animationInOut = new AnimateInOut(this.model, this.resource.animations)
        this.addAnimationDebug()

        this.uiSettings = {
          slug: "mobility",
          title: ['Mobilität &', 'Infrastruktur'],
          backgroundColor: "#FF7E6C",
          foreground: "light",
        }
        this.updateUI = new UpdateUI(this.uiSettings)
        this.pointsOfInterest = new PointsOfInterest(this.uiSettings.slug);

        // show scene
        this.camera.on('scene4', () => { this.showScene() })

        //hide scene
        this.camera.on('scene1', () => { this.hideScene() })
        this.camera.on('scene2', () => { this.hideScene() })
        this.camera.on('scene3', () => { this.hideScene() })
        this.camera.on('scene5', () => { this.hideScene() })
        this.camera.on('scene6', () => { this.hideScene() })
        this.camera.on('scene7', () => { this.hideScene() })

    }

    setModel()
    {
        this.model = this.resource.scene
        //mirror street so it's not cut at the bottom as much
        this.model.scale.set(-0.5, 0.5, 0.5)
        this.model.position.set(0, -1.5, 0)
        // this.model.rotation.set(0, , 0)
        this.scene.add(this.model)
    }

    addAnimationDebug()
    {
        // Debug
        if(this.debug.active)
        {
            const debugObject = {
                playIn: () => { this.animationInOut.play('in') },
                playOut: () => { this.animationInOut.play('out') },
            }
            this.debugFolder.add(debugObject, 'playIn')
            this.debugFolder.add(debugObject, 'playOut')
        }
    }

    hideScene() {
      if(this.sceneIsActive == true) {
        this.sceneIsActive = false;
        this.animationInOut.play('out')
        this.pointsOfInterest.hidePoints()
      }
    }

    showScene() {
      this.sceneIsActive = true;
      this.animationInOut.play('in')
      this.pointsOfInterest.showPoints()
      this.updateUI.setActive()
    }

    update()
    {
      this.animationInOut.update()
      this.pointsOfInterest.update()
    }
}
