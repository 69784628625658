export default class Footnotes {
    init(root) {
      this.root = root
      this.footnotes = root.querySelectorAll('article-footnote')

      if(this.footnotes.length > 0) {
        this.addFootnoteAnchors()
      }
    }

    addFootnoteAnchors() {
      this.footnotes.forEach((footnote, index) => {
        const sourceId = 'source-' + (index + 1);
        const anchorElement = document.createElement('a');
        anchorElement.setAttribute('href', '#' + sourceId);
        anchorElement.setAttribute('id', 'footnote-' + (index + 1));
        anchorElement.innerHTML = index + 1;
        anchorElement.classList.add('article-footnote')
        footnote.appendChild(anchorElement);
      });
    }
}
