import * as THREE from 'three'
import Experience from '../Experience.js';
import gsap from 'gsap';
import { lerp, clamp } from './../../utils/math.js'

export default class District {
  constructor() {
    this.experience = new Experience();
    this.scene = this.experience.scene;
    this.resources = this.experience.resources;
    this.camera = this.experience.camera;
    this.time = this.experience.time;
    this.debug = this.experience.debug;

    this.fromProgress = 0;
    this.targetProgress = 0;
    this.animationTime = 1;
    this.currentProgress = 0;

    // this.sceneIsActive = false;

    this.camera.on('scene5', () => this.showGrid());
    this.camera.on('scene6', () => this.showRandom());
    this.camera.on('scene7', () => this.showGrid());
    this.camera.on('scene1', () => this.hideGrid());
    this.camera.on('scene2', () => this.hideGrid());
    this.camera.on('scene3', () => this.hideGrid());
    this.camera.on('scene4', () => this.hideGrid());

    this.resource = this.resources.items.districtHouseModel;
    this.setModel();
    this.addGridStatesTl ()
  }

  setModel() {
    this.model = this.resource.scene;
    this.model.scale.set(0.5, 0.5, 0.5);
    this.model.position.set(0, -1.5, 0);

    // Matcap
    this.matCaps = [ //add colors in order of meshes
      this.resources.items.wandMatcap,
      this.resources.items.roofMatcap,
      this.resources.items.wandMatcap,
      this.resources.items.wandMatcap,
      this.resources.items.wandMatcap,
    ]

    let childIndex = 0;

    this.model.traverse((child) => {
      if (child.isMesh) {
        const matcapTexture = this.matCaps[childIndex];
        matcapTexture.encoding = THREE.sRGBEncoding;
        const material = new THREE.MeshMatcapMaterial({ matcap: matcapTexture, color: 0xffffff });
        // Replace the existing material with the new material
        child.material = material;
        childIndex++
      }
    });

    const offsetX = 8;
    const offsetZ = 8;
    const offsetY = -10;
    this.houseGrid = [];

    for (let x = -2; x <= 2; x++) {
      for (let z = -2; z <= 2; z++) {
        const clone = this.model.clone();
        const posX = x * offsetX;
        const posY = offsetY;
        const posZ = z * offsetZ;
        clone.position.set(posX, posY, posZ);
        clone.visible = false;
        this.scene.add(clone);
        this.houseGrid.push(clone);
      }
    }
  }

  //one animation timeline for all states. The states are transitioned with a lerp of the progress in the update function
  addGridStatesTl() {
    this.gridTl = gsap.timeline({ paused: true });
    const centerIndex = Math.floor(this.houseGrid.length / 2);

    this.houseGrid.forEach((clone, index) => {
      const distance = Math.abs(centerIndex - index);
      const delay = distance * 0.02;
      const longestDelay = 12 * 0.02;
      const duration = 1 - longestDelay

      //appear
      this.gridTl.fromTo(clone.position,{
        y: -4.8
      }, {
        y: -1.5,
        duration: duration,
        ease: 'power2.inOut',
        delay: delay,
        onStart: () => {
          clone.visible = true
        },
        onReverseComplete: () => {
          clone.visible = false
        }
      }, 0)

      //move up and rotate (planning)
      if(index != centerIndex) {
        this.gridTl.fromTo(clone.position,{
          y: -1.5
        }, {
          y: 1.5 + Math.random() * 3,
          duration: duration,
          ease: 'power2.inOut',
          delay: delay
        }, 1)

        this.gridTl.fromTo(clone.rotation,{
          x: 0,
          y: 0,
          z: 0,
        }, {
          x: Math.random() * Math.PI,
          y: Math.random() * Math.PI,
          z: Math.random() * Math.PI,
          duration: duration,
          ease: 'power2.inOut',
          delay: delay
        }, 1)
      }

    })
  }

  showGrid() {
    this.targetProgress = 0.5;
    this.fromProgress = this.currentProgress
    this.animationTime = 0
  }

  hideGrid() {
    this.targetProgress = 0;
    this.fromProgress = this.currentProgress
    this.animationTime = 0
  }

  showRandom() {
    this.targetProgress = 1;
    this.fromProgress = this.currentProgress
    this.animationTime = 0
  }

  update() {
    if(this.targetProgress != this.currentProgress) {
      this.currentProgress = lerp(this.fromProgress, this.targetProgress, this.animationTime);
      this.gridTl.progress(this.currentProgress)
      this.animationTime = clamp(this.animationTime + (this.time.delta * 0.001))
    }
  }

}
