import * as THREE from 'three'
import Experience from '../Experience.js'
import AnimateInOut from '../Utils/AnimateInOut.js'
import UpdateUI from '../Utils/UpdateUI.js'
import PointsOfInterest from '../Utils/PointsOfInterest.js'

export default class Energy
{
    constructor()
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.camera = this.experience.camera
        this.resources = this.experience.resources
        this.time = this.experience.time
        this.debug = this.experience.debug

        this.sceneIsActive = false;


        //HTLM Elements
        this.bodyElement = document.querySelector('body');
        this.topicTitle = document.querySelector('.topic-title');
        this.jumpLinkLabel = document.querySelector('.jumplink-label');

        // Debug
        if(this.debug.active) {
          this.debugFolder = this.debug.ui.addFolder('energy')
        }

        // Resource
        this.resource = this.resources.items.energyModel
        this.setModel()
        this.animationInOut = new AnimateInOut(this.model, this.resource.animations)
        this.addAnimationDebug()

        this.uiSettings = {
          slug: "ressources",
          title: ['Ressourcen', '& Klima'],
          backgroundColor: "#2D1F45",
          foreground: "light",
        }
        this.updateUI = new UpdateUI(this.uiSettings)
        this.pointsOfInterest = new PointsOfInterest(this.uiSettings.slug);


        // show scene
        this.camera.on('scene3', () => { this.showScene() })

        //hide scene
        this.camera.on('scene1', () => { this.hideScene() })
        this.camera.on('scene2', () => { this.hideScene() })
        this.camera.on('scene4', () => { this.hideScene() })

    }

    setModel()
    {
      this.model = this.resource.scene;
      this.model.scale.set(0.5, 0.5, 0.5);
      this.model.position.set(0, -1.5, 0);
      this.scene.add(this.model);

      // Traverse the object hierarchy to find and modify the materials
      this.model.traverse((child) => {
        if (child.isMesh) {
          // Modify the materials of the objects with image texture
          if (child.material.map) {
            const material = new THREE.MeshBasicMaterial({
              map: child.material.map, // Set the same image texture
            });
            child.material = material;
          }
        }
      });

        // Add debug controls
        if(this.debug.active) {

        }
    }

    addAnimationDebug()
    {
        // Debug
        if(this.debug.active)
        {
            const debugObject = {
                playIn: () => { this.animationInOut.play('in') },
                playOut: () => { this.animationInOut.play('out') },
            }
            this.debugFolder.add(debugObject, 'playIn')
            this.debugFolder.add(debugObject, 'playOut')
        }
    }

    hideScene() {
      if(this.sceneIsActive == true) {
        this.sceneIsActive = false;
        this.animationInOut.play('out')
        this.pointsOfInterest.hidePoints();
      }
    }

    showScene() {
      this.sceneIsActive = true;
      this.animationInOut.play('in')
      this.pointsOfInterest.showPoints();
      this.updateUI.setActive()
    }

    update()
    {
      this.animationInOut.update()
      this.pointsOfInterest.update()
    }
}
