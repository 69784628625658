import * as THREE from 'three'
import gsap from 'gsap'
import Experience from '../Experience.js'
import AnimateInOut from '../Utils/AnimateInOut.js'
import UpdateUI from '../Utils/UpdateUI.js'
import PointsOfInterest from '../Utils/PointsOfInterest.js'
import { map, clamp } from './../../utils/math.js'

export default class House
{
    constructor()
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.camera = this.experience.camera
        this.time = this.experience.time
        this.debug = this.experience.debug
        this.sizes = this.experience.sizes

        this.sceneIsActive = false;

        // Debug
        if(this.debug.active)
        {
            this.debugFolder = this.debug.ui.addFolder('house')
        }

        // Resource
        this.resource = this.resources.items.basicHouseModel

        this.setModel()

        // this.setAnimation()
        this.animationInOut = new AnimateInOut(this.model, this.resource.animations)
        this.addAnimationDebug()


        this.uiSettings = {
          slug: "lifecycle",
          title: ['Wohnen &', 'Lebenszyklus'],
          backgroundColor: "#FAFAFA",
          foreground: "dark",
        }
        this.updateUI = new UpdateUI(this.uiSettings)

        this.pointsOfInterest = new PointsOfInterest(this.uiSettings.slug);


        this.siteIntro = document.querySelector('.siteintro');
        if (this.siteIntro) {
          this.updateIntro();
        }

        // show scene
        this.camera.on('scene1', () => { this.showScene() })

        //hide scene
        this.camera.on('scene2', () => { this.hideScene() })
        this.camera.on('scene3', () => { this.hideScene() })
        this.camera.on('scene4', () => { this.hideScene() })
        this.camera.on('scene5', () => { this.hideScene() })
        this.camera.on('scene6', () => { this.hideScene() })
        this.camera.on('scene7', () => { this.hideScene() })
        // this.camera.on('scene5', () => {
        //   if(!this.sceneIsActive) {
        //     this.showScene()
        //   }
        // })
        // this.camera.on('scene6', () => {
        //   if(!this.sceneIsActive) {
        //     this.showScene()
        //   }
        // })
        // this.camera.on('scene7', () => {
        //   if(!this.sceneIsActive) {
        //     this.showScene()
        //   }
        // })
    }

    setModel()
    {
        this.model = this.resource.scene
        this.model.scale.set(0.5, 0.5, 0.5)
        this.model.position.set(0, -1.5, 0)
        this.scene.add(this.model)

        this.model.traverse((child) =>
        {
            if(child instanceof THREE.Mesh)
            {
                child.receiveShadow = true
                child.castShadow = true
            }
        })
    }

    updateIntro() {
      window.scrollTo(0, 0);
      document.querySelector('body').style.overflow = 'hidden';
      let scrollintroY = 0;
      const siteIntroWrapper = document.querySelector('.siteintro-wrapper');
      const siteIntroSkip = document.querySelector('.siteintro-skip');

      siteIntroSkip.addEventListener('click', () => {
        this.siteIntro.style.display = 'none';
        introTl.play()
        this.showInterface()
        //Matomo Tracking
        _paq.push(['trackEvent', 'Navigation', 'Intro', 'Skip Intro Button']);
      })

      //animate in
      let n = 0;
      this.model.traverse((child) =>{
        if(child instanceof THREE.Mesh) {
          gsap.from(child.scale, {
            x: 0,
            y: 0,
            z: 0,
            delay: 1 + (n * 0.05),
            duration: 0.4,
          })
          n++;
        }
      })

      this.model.visible = true;
      this.sceneIsActive = true;
      // this.animationInOut.play('in', false)

      let multiplier = 1;
      if(this.sizes.width < this.sizes.height) { //portrait mode
        multiplier = 0.5;
      }

      this.introTranslations = [
        {x: multiplier * 300, y: 50, z: 200, rx: 0.3, ry: 0, rz: 0}, //blue cylinder
        {x: multiplier * -300, y: 50, z: -300, rx: 0, ry: 0.1, rz: 0}, //brown block
        {x: multiplier * 400, y: 400, z: 0, rx: -0.5, ry: 0, rz: 0}, //blue triangle / pyramid
        {x: multiplier * -300, y: 300, z: 300, rx: 0.4, ry: 0, rz: 0}, // yellow trinalge / pyramid
        {x: multiplier * 0, y: 400, z: 0, rx: 0, ry: 1.5, rz: 0}, //purple chimney
        {x: multiplier * 0, y: 0, z: 400, rx: 0, ry: 0, rz: -0.6}, //pink circle quarter
        {x: multiplier * 400, y: 0, z: 0, rx: 0, ry: -0.8, rz: 0}, //red arch
      ]

      let introTl = gsap.timeline({ paused: true });

      let i = 0;
      this.model.traverse((child) =>{
        if(child instanceof THREE.Mesh) {
          // console.log(child.position.x, child.position.y, child.position.z  )
          introTl.from(child.position, {
            x: child.position.x + this.introTranslations[i].x,
            y: child.position.y + this.introTranslations[i].y,
            z: child.position.z + this.introTranslations[i].z,
            duration: 1,
            ease: "power2.inOut",
          }, 0);
          introTl.from(child.rotation, {
            x: child.rotation.x + this.introTranslations[i].rx,
            y: child.rotation.y + this.introTranslations[i].ry,
            z: child.rotation.z + this.introTranslations[i].rz,
            duration: 1,
            ease: "power2.inOut",
          }, 0);
          i++;
        }
    })

      const updateScrollintroY = () => {
        scrollintroY = this.siteIntro.scrollTop;
        this.siteIntroHeight = siteIntroWrapper.offsetHeight;
        this.introScroll = scrollintroY + this.sizes.height;

        let introProgress = map(this.introScroll, this.sizes.height, this.siteIntroHeight, 0, 1)
        // console.log(introProgress)
        introTl.progress(introProgress)

        if (this.siteIntroHeight - 200 <= this.introScroll) { //-100 offset to triger on mobile (ios url-bar)
          this.hideIntro()
        }
      };

      this.siteIntro.addEventListener('scroll', updateScrollintroY, false); // Modern browsers

      this.sizes.on('resize', () => {
        this.siteIntroHeight = siteIntroWrapper.offsetHeight;
      });
    }

    hideIntro() {

      //Matomo Tracking
      _paq.push(['trackEvent', 'Navigation', 'Intro', 'Show Intro (How to interact)']);
      // console.log('hide intro!');
      // this.animationInOut.play('in', false)
      this.siteIntro.style.display = 'none';

      //rotation hint circle animation
      this.rotationHintTl = gsap.timeline({ paused: true });
      this.rotationHintCircle = document.querySelector('.rotation-hint-circle');
      this.rotationHintText = document.querySelector('.rotation-hint-text');
      this.switchViewButton = document.querySelector('.switchview');

      this.rotationHintTl.fromTo([this.rotationHintCircle, this.rotationHintText], {
        scale: 1.2,
        opacity: 0,
      }, {
        scale: 1.2,
        opacity: 1,
        duration: 0.2,
        delay: 1
      })
      .to(this.rotationHintCircle, {
        scale: 1,
        duration: 0.2,
        delay: 0.4,
      })
      .to(this.rotationHintCircle, {
        x: 100,
        duration: 1,
        onStart: () => {
          this.experience.camera.autoRotate(true, 6);
        },
        onComplete: () => {
          this.experience.camera.autoRotate(false, 0);
        }
      })
      .to(this.rotationHintCircle, {
        scale: 1.2,
        duration: 0.2
      })
      .to(this.rotationHintCircle, {
        scale: 1,
        duration: 0.2,
        delay: 1
      })
      .to(this.rotationHintCircle, {
        x: -100,
        duration: 1,
        onStart: () => {
          this.experience.camera.autoRotate(true, -6);
        },
        onComplete: () => {
          this.experience.camera.autoRotate(false, 0);
        }
      })
      .to(this.rotationHintCircle, {
        scale: 1.2,
        duration: 0.2
      })
      .to(this.switchViewButton, {
        opacity: 1,
        duration: 0.2
      })
      for (var i = 0; i < 3; i++) { //wiggle button
        this.rotationHintTl.to(this.switchViewButton, {
          rotation: 2,
          duration: 0.2
        })
        .to(this.switchViewButton, {
          rotation: -2,
          duration: 0.2
        })
      }
      this.rotationHintTl.to(this.switchViewButton, {
        rotation: 0,
        duration: 0.2
      })
      .to([this.rotationHintCircle, this.rotationHintText], {
        opacity: 0,
        duration: 0.2,
        delay: 0.5,
        onCompete: () => {
          this.showInterface()
        }
      })

      //quick rotation hint
      this.rotationHintTl.play()
    }

    showInterface() {
      document.querySelector('body').classList.remove('siteintroactive')
      document.querySelector('body').style.overflow = 'auto';
      // this.pointsOfInterest.showPoints()
      // this.updateUI.setActive()
      this.camera.currentScene = null
      this.camera.checkSceneAngle()
    }

    addAnimationDebug()
    {
        // Debug
        if(this.debug.active)
        {
            const debugObject = {
                playIn: () => { this.animationInOut.play('in') },
                playOut: () => { this.animationInOut.play('out') },
            }
            this.debugFolder.add(debugObject, 'playIn')
            this.debugFolder.add(debugObject, 'playOut')
        }
    }

    hideScene() {
      if(this.sceneIsActive == true) {
        this.sceneIsActive = false;
        this.animationInOut.play('out')
        this.pointsOfInterest.hidePoints()
      }
    }

    showScene() {
      this.sceneIsActive = true;
      this.animationInOut.play('in')
      this.pointsOfInterest.showPoints()
      this.updateUI.setActive()
    }

    update()
    {
        this.animationInOut.update()
        this.pointsOfInterest.update()
    }
}
