import * as THREE from 'three'
import gsap from "gsap";
import Experience from '../Experience.js'
import AnimateInOut from '../Utils/AnimateInOut.js'
import UpdateUI from '../Utils/UpdateUI.js'
import PointsOfInterest from '../Utils/PointsOfInterest.js'

export default class Controlling
{
    constructor()
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.camera = this.experience.camera
        this.resources = this.experience.resources
        this.time = this.experience.time
        this.debug = this.experience.debug

        this.sceneIsActive = false;


        // Debug
        if(this.debug.active)
        {
            this.debugFolder = this.debug.ui.addFolder('controlling')
        }

        // Resource
        this.resource = this.resources.items.okonomieModel

        this.setModel()

        this.uiSettings = {
          slug: "controlling",
          title: ['Steuerung &', 'Verwaltung'],
          backgroundColor: "#F3F1F7",
          foreground: "dark",
        }
        this.updateUI = new UpdateUI(this.uiSettings)
        this.pointsOfInterest = new PointsOfInterest(this.uiSettings.slug);


        // show scene
        this.camera.on('scene7', () => { this.showScene() })

        //hide scene
        this.camera.on('scene1', () => { this.hideScene() })
        this.camera.on('scene2', () => { this.hideScene() })
        this.camera.on('scene3', () => { this.hideScene() })
        this.camera.on('scene4', () => { this.hideScene() })
        this.camera.on('scene5', () => { this.hideScene() })
        this.camera.on('scene6', () => { this.hideScene() })

    }

    setModel()
    {
        this.geometry = new THREE.PlaneGeometry(32, 32);
        this.geometry02 = new THREE.PlaneGeometry(32, 32);

        //Plane 01
        this.texture = this.resources.items.controllingTexture01;
        this.material = new THREE.MeshBasicMaterial({ map: this.texture, transparent: true });
        this.material.opacity = 0;
        this.model = new THREE.Mesh(this.geometry, this.material);
        this.model.position.set(0, -1.2, 0)
        this.model.rotation.set(-Math.PI * 0.5, 0, 0)
        this.model.visible = false;
        this.scene.add(this.model)

        //Plane 02
        this.texture02 = this.resources.items.controllingTexture02;
        this.material02 = new THREE.MeshBasicMaterial({ map: this.texture02, transparent: true });
        this.material02.opacity = 0;
        this.model02 = new THREE.Mesh(this.geometry02, this.material02);
        this.model02.position.set(0, -1, 0)
        this.model02.rotation.set(-Math.PI * 0.5, 0, 0)
        this.model02.visible = false;
        this.scene.add(this.model02)
    }

    hideScene() {
      if(this.sceneIsActive == true) {
        this.sceneIsActive = false;
        // this.animationInOut.play('out')
        gsap.to([this.model02.material, this.model.material], {
          opacity: 0,
          duration: 0.6,
          onComplete: () => {
            this.model.visible = false;
            this.model02.visible = false;
          }
        })
        this.pointsOfInterest.hidePoints();
      }
    }

    showScene() {
      this.sceneIsActive = true;
      // this.animationInOut.play('in')
      gsap.to([this.model02.material, this.model.material], {
        opacity: 1,
        duration: 0.6,
        delay: 0.5,
        onStart: () => {
          this.model.visible = true;
          this.model02.visible = true;
        }
      })
      this.pointsOfInterest.showPoints();
      this.updateUI.setActive()
    }

    update()
    {
      // this.animationInOut.update()
      this.pointsOfInterest.update()
    }
}
