import '../scss/app.scss';

// Your JS Code goes here
import Header from '../components/header/header.js'
import Jumplink from '../components/jumplink/jumplink.js'
import Gallery from '../components/gallery/gallery.js'
import Footnotes from '../components/footnotes/footnotes.js'
import Subpageheader from '../components/subpageheader/subpageheader.js'
import Article from '../components/article/article.js'
// import WebGL from '../components/webgl/webgl.js'
import barba from '@barba/core';
import { gsap } from "gsap";

//import MatomoOptOut from "./utils/matomo-opt-out.js";

let headerEl = document.querySelector(".header");
let header = new Header();
header.init(headerEl)


let galleryInstances = []
document.querySelectorAll(".gallery").forEach(el => {
  let gallery = new Gallery();
  gallery.init(el)
  galleryInstances.push(gallery)
})

let jumplinkEl = document.querySelector(".jumplink");
let jumplink = new Jumplink();
jumplink.init(jumplinkEl)

let main = document.querySelector("main");
let footnotes = new Footnotes();
footnotes.init(main)

let subpageheaderEl = document.querySelector(".subpageheader");
let subpageheader;
if(subpageheaderEl) {
  subpageheader = new Subpageheader();
  subpageheader.init(subpageheaderEl)
}

let articleEl = document.querySelector(".article, .info");
let article;
if(articleEl) {
  article = new Article();
  article.init(articleEl)
}


import Experience from './Experience/Experience.js'
const experience = new Experience(document.querySelector('canvas.webgl'))



let scrollX = 0
let scrollY = 0


barba.init({
	preventRunning: true,
	debug: false,
	// logLevel: 'debug',
	transitions: [
	{
	  name: 'toHome',
	  to: { //this transition is only applied when navigating TO home
			namespace: ['home']
	  },
	  leave(data) {
      return gsap.to(data.current.container, {
        opacity: 0,
        duration: 0.5,
        y: 200,
      });
	  },
	  enter(data) {
      document.querySelector('body').classList.remove('nothome')
      experience.camera.currentScene = null
      experience.time.resume()
      window.scrollTo(scrollX, scrollY);
      // console.log('scroll to')
      // console.log(scrollY)
	  }
	},
	{
	  name: 'fromHome',
	  from: { //this transition is only applied when navigating FROM home
			namespace: ['home']
	  },
	  leave(data) {
      scrollX = barba.history.current.scroll.x;
      scrollY = barba.history.current.scroll.y;
      /* On leave, hide the current model before pausing time */
      experience.time.pause()
      document.querySelector('body').style.backgroundColor = "#FFFFFF"
      document.querySelector('body').classList.add('nothome')
		  return gsap.to(data.current.container, {
        opacity: 0,
        duration: 0.5,
        delay: 0
		  });
		},
		enter(data) {
      data.next.container.style.position = 'fixed';
      document.documentElement.style.scrollBehavior = "auto";

		  return gsap.from(data.next.container, {
        opacity: 0,
        duration: 0.5,
        y: 200,
        delay: 0.625,
        onComplete: () => {
          window.scrollTo(0, 0);
          data.next.container.style.position = 'absolute';
          document.documentElement.style.scrollBehavior = "smooth";
        },
		  });
		}
	},
	{ //last defined transition is the default:
		name: 'opacity-transition',
	  leave(data) {
      document.querySelector('body').classList.add('nothome')
		  return gsap.to(data.current.container, {
        opacity: 0,
        duration: 0.25,
        delay: 0
		  });
		},
		enter(data) {
      data.next.container.style.position = 'fixed';
      document.documentElement.style.scrollBehavior = "auto";

		  return gsap.from(data.next.container, {
        opacity: 0,
        duration: 0.5,
        y: 200,
        delay: 0.25,
        onComplete: () => {
          window.scrollTo(0, 0);
          data.next.container.style.position = 'absolute';
          document.documentElement.style.scrollBehavior = "smooth";
        },
		  });
		}
	},
	]
});

barba.hooks.enter((data) => {

  //Matomo tracking
  //_paq.push(['trackPageView']);
  _paq.push(['trackEvent', 'Navigation', 'Page load', data.next.namespace]);
  //console.log(data.next.namespace)

  //look for matomo optout
  if(data.next.container.querySelector('#matomo-opt-out')) {
    const script = document.createElement('script');
    script.src = "https://matomo.leben-vor-der-stadt.de/index.php?module=CoreAdminHome&action=optOutJS&divId=matomo-opt-out&language=auto&backgroundColor=FFFFFF&fontColor=000000&fontSize=12px&fontFamily=Arial&showIntro=1";
    script.addEventListener('load', () => {
      setTimeout(() => {
        optOutInit();
      }, 3000);
    });
    document.head.appendChild(script);
  }

  if (history.scrollRestoration) {
    history.scrollRestoration = 'manual';
  }
  data.next.container.querySelectorAll('.gallery').forEach(el => {
    let gallery = new Gallery();
    gallery.init(el)
    galleryInstances.push(gallery)
  })

  let footnotes = new Footnotes();
  footnotes.init(data.next.container)

  let subpageheaderEl = data.next.container.querySelector(".subpageheader");
  if(subpageheaderEl) {
    subpageheader = new Subpageheader();
    subpageheader.init(subpageheaderEl)
  }

  if(data.next.container.classList.contains('article') || data.next.container.classList.contains('info')) {
    article = new Article();
    article.init(data.next.container)
  }

});

barba.hooks.leave((data) => {
  galleryInstances.forEach(inst => {
    inst.destroy()
  })

  if(subpageheader) {
    subpageheader.destroy();
  }

  if(article) {
    article.destroy()
  }
});
