import * as THREE from 'three'
import Experience from '../Experience.js'

export default class Floor {
  constructor() {
    this.experience = new Experience()
    this.scene = this.experience.scene
    this.renderer = this.experience.renderer.instance
    this.camera = this.experience.camera.instance

    // Set up the floor clipping plane
    this.setFloorClippingPlane()

    // Update camera settings
    // this.camera.position.set(0, 2, 10)
    // this.camera.lookAt(0, 0, 0)
    // this.camera.near = 0.1
    // this.camera.far = 1000
    // this.camera.updateProjectionMatrix()
  }

  setFloorClippingPlane() {
    // Set up the floor clipping plane
    const planePosition = new THREE.Vector3(0, -1.5, 0);
    const planeNormal = new THREE.Vector3(0, 1, 0);
    const plane = new THREE.Plane(planeNormal, 0);
    plane.applyMatrix4(this.scene.matrixWorld);

    this.renderer.clippingPlanes = [plane];
    this.renderer.localClippingEnabled = true;

    // Set the plane position for onBeforeRender
    this.scene.onBeforeRender = () => {
      plane.constant = -planePosition.dot(planeNormal);
      plane.normal.copy(planeNormal);
    };
  }
}
