import * as THREE from 'three'
import Experience from '../Experience.js'

export default class PointsOfInterest
{
  constructor(slug)
  {
      // Setup
      this.experience = new Experience()
      this.time = this.experience.time
      this.sizes = this.experience.sizes
      this.camera = this.experience.camera.instance
      this.pointsWrapper = document.querySelector('.points');
      this.elements = this.pointsWrapper.querySelectorAll('.point[data-slug="' + slug + '"]');
      this.points = []

      this.elements.forEach(el => {
        this.points.push({
          element: el,
          position: new THREE.Vector3(el.dataset.x, el.dataset.y, el.dataset.z)
        })
      })
  }

  showPoints() {
    for(const point of this.points) {
      point.element.classList.add('visible')
    }
  }

  hidePoints() {
    for(const point of this.points) {
      point.element.classList.remove('visible')
    }
  }

  update()
  {

    // Go through each point
    for(const point of this.points)
    {
        // Get 2D screen position
        const screenPosition = point.position.clone()
        // console.log(this.camera)
        screenPosition.project(this.camera)

        const translateX = screenPosition.x * this.sizes.width * 0.5
        const translateY = - screenPosition.y * this.sizes.height * 0.5
        point.element.style.transform = `translateX(${translateX}px) translateY(${translateY}px)`
    }
  }
}



