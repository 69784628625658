import * as THREE from 'three';
import Experience from '../Experience.js';
import gsap from 'gsap';
import UpdateUI from '../Utils/UpdateUI.js';
import PointsOfInterest from '../Utils/PointsOfInterest.js';

export default class Planning {
  constructor() {
    this.experience = new Experience();
    this.scene = this.experience.scene;
    this.resources = this.experience.resources;
    this.camera = this.experience.camera;
    this.time = this.experience.time;
    this.debug = this.experience.debug;
    this.houseGrid = this.experience.world.district.houseGrid;

    this.sceneIsActive = false;

    this.uiSettings = {
      slug: 'planning',
      title: ['Planung &', 'Gestaltung'],
      backgroundColor: '#FBF08A',
      foreground: 'dark',
    };
    this.updateUI = new UpdateUI(this.uiSettings);
    this.pointsOfInterest = new PointsOfInterest(this.uiSettings.slug);

    this.camera.on('scene6', () => this.showScene());
    this.camera.on('scene1', () => this.hideScene());
    this.camera.on('scene2', () => this.hideScene());
    this.camera.on('scene3', () => this.hideScene());
    this.camera.on('scene4', () => this.hideScene());
    this.camera.on('scene5', () => this.hideScene());
    this.camera.on('scene7', () => this.hideScene());
  }

  showScene() {
    this.sceneIsActive = true;
    this.pointsOfInterest.showPoints();
    this.updateUI.setActive();

    const centerIndex = Math.floor(this.houseGrid.length / 2);
  }

  hideScene() {
    if (this.sceneIsActive) {
      this.sceneIsActive = false;
      this.pointsOfInterest.hidePoints();
    }
  }

  update()
  {
    this.pointsOfInterest.update()
  }
}
