import * as THREE from 'three'
import Experience from '../Experience.js'
import gsap from "gsap";

export default class AnimateInOut
{
  constructor(model, resources)
  {
      // Setup
      this.experience = new Experience()
      this.model = model
      this.resources = resources
      this.animation = {}
      this.animationObjects = []
      this.time = this.experience.time

      this.model.ogScaleX = this.model.scale.x
      this.model.ogScaleY = this.model.scale.y
      this.model.ogScaleZ = this.model.scale.z

      //hide model
      this.model.visible = false;

      //pair the in and out animations and join them in one mixer
      this.resources.forEach((action, i) => {
        // console.log(action)
        if(action.name.slice(-1) == "2") { //ends with a "2" -> it's an out animation
          // console.log('skip') //skip for now
          return
        } else {
          let mixer = new THREE.AnimationMixer(this.model)
          let counterPart = this.resources.find((obj) => obj.name === action.name + "2");
          let inClip = mixer.clipAction(action)
          let outClip = mixer.clipAction(counterPart)


          this.animationObjects.push({
            mixer: mixer, inClip: inClip, outClip: outClip, current: null
          })
        }
      })
  }

  play(direction = 'in', generalMotion = true)
  {
    this.animationObjects.forEach(obj => {
      let newAction;
      if(direction == "in") {
        newAction = obj.inClip
      } else if(direction == "out") {
        newAction = obj.outClip
      }

      newAction.clampWhenFinished = true;
      newAction.repetitions = 0;
      newAction.loop = false;
      newAction.paused = false;
      newAction.enabled = true;
      newAction.reset()
      newAction.play()

      let oldAction;
      if(obj.current) {
        oldAction = obj.current
        obj.current = newAction
      } else {
        obj.current = newAction
        return
      }

      newAction.crossFadeFrom(oldAction, 1)
    })
    if(generalMotion === true) {
      this.generalMotion(direction);
    }
  }

  update(direction) //update all, all of the time?
  {
    this.animationObjects.forEach(obj => {
      obj.mixer.update(this.time.delta * 0.001)
    })
  }

  generalMotion(direction) {
    let tl = gsap.timeline({ paused: true });
    let timeScale = 1;

    tl.to(this.model.scale, {
      x: 0.8 * this.model.ogScaleX,
      y: 0.8 * this.model.ogScaleY,
      z: 0.8 * this.model.ogScaleZ,
      duration: 0.25 * timeScale,
      ease: "power2.inOut",
    });

    tl.to(this.model.scale, {
      x: 0.9 * this.model.ogScaleX,
      y: 0.9 * this.model.ogScaleY,
      z: 0.9 * this.model.ogScaleZ,
      duration: 0.375 * timeScale,
      ease: "power2.in",
      onComplete: () => {
        if(direction === "in") {
          this.model.visible = true;
        } else {
          this.model.visible = false;
        }
      },
    });

    tl.to(this.model.scale, {
      x: 1 * this.model.ogScaleX,
      y: 1 * this.model.ogScaleY,
      z: 1 * this.model.ogScaleZ,
      duration: 0.375 * timeScale * 2,
      ease: "power4.out",
    });

    tl.play()
  }
}
