export default class Subpageheader {
  init(root) {
    this.root = root;
    this.citeButton = this.root.querySelector('.cite-button');
    this.linkCopyButton = this.root.querySelector('.link-copy-button');
    this.dialogCitation = this.root.querySelector('.dialog-citation');
    this.dialogLinkCopy = this.root.querySelector('.dialog-link-copy');
    this.citationTimeout = null;
    this.linkCopyTimeout = null;

    if (this.citeButton) {
      this.citeButton.addEventListener('click', this.toggleCitationDialog);
    }
    if (this.linkCopyButton) {
      this.linkCopyButton.addEventListener('click', this.copyToClipboardAndShowDialog);
    }
    if (this.dialogCitation) {
      this.dialogCitation.querySelector('.dialog-copy-button').addEventListener('click', this.copyCitationAndToggleDialogs);
    }
  }

  toggleCitationDialog = () => {
    if (this.dialogCitation) {
      //Matomo Tracking
      _paq.push(['trackEvent', 'Article Action Buttons', 'Button Clicked', 'Zitieren']);
      this.dialogCitation.classList.toggle('is-visible');
      clearTimeout(this.citationTimeout);
      this.citationTimeout = setTimeout(() => {
        this.dialogCitation.classList.remove('is-visible');
      }, 6000);
    }
  };

  copyToClipboardAndShowDialog = () => {
    if (this.linkCopyButton && this.dialogLinkCopy) {
      //Matomo Tracking
      _paq.push(['trackEvent', 'Article Action Buttons', 'Button Clicked', 'Link kopieren']);
      const linkCopyButton = this.linkCopyButton;
      const link = linkCopyButton.dataset.link;

      clearTimeout(this.linkCopyTimeout);
      navigator.clipboard.writeText(link).then(() => {
        this.dialogLinkCopy.classList.add('is-visible');
        if (this.dialogCitation) {
          this.dialogCitation.classList.remove('is-visible');
        }
        this.linkCopyTimeout = setTimeout(() => {
          this.dialogLinkCopy.classList.remove('is-visible');
        }, 2000);
      });
    }
  };

  copyCitationAndToggleDialogs = () => {
    if (this.dialogCitation && this.dialogLinkCopy) {
      const citationContents = this.dialogCitation.querySelector('.contents');
      const citationText = citationContents.textContent;

      clearTimeout(this.linkCopyTimeout);
      navigator.clipboard.writeText(citationText).then(() => {
        this.dialogCitation.classList.remove('is-visible');
        this.dialogLinkCopy.classList.add('is-visible');
        this.linkCopyTimeout = setTimeout(() => {
          this.dialogLinkCopy.classList.remove('is-visible');
        }, 2000);
      });
    }
  };

  destroy = () => {
    if (this.citeButton) {
      this.citeButton.removeEventListener('click', this.toggleCitationDialog);
    }
    if (this.linkCopyButton) {
      this.linkCopyButton.removeEventListener('click', this.copyToClipboardAndShowDialog);
    }
    if (this.dialogCitation) {
      this.dialogCitation.querySelector('.dialog-copy-button').removeEventListener('click', this.copyCitationAndToggleDialogs);
    }
    clearTimeout(this.citationTimeout);
    clearTimeout(this.linkCopyTimeout);
  };
}
