import Time from './../../js/Experience/Utils/Time.js'
import {scroll, doc} from './../../js/utils/events.js'

export default class Jumplink {
    init(root) {
      this.root = root
      this.time = new Time()
      this.isFixed = false;
      this.linkElement = root.querySelector('.jumplink-pill')

      // Time tick event
      this.time.on('tick', () => {
        if(scroll.y > doc.h * 0.9 && this.isFixed != true) {
          this.isFixed = true;
          this.switchLinkDirection('up')
        } else if(scroll.y < doc.h * 0.9 && this.isFixed === true) {
          this.isFixed = false;
          this.switchLinkDirection('down')
        }
      })
    }

    switchLinkDirection(direction) {
      this.root.classList.toggle('directs-up');
      if(direction === "up") {
        this.linkElement.setAttribute('href', "#webgl");
      } else {
        this.linkElement.setAttribute('href', "#container");
      }
    }
}