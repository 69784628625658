import * as THREE from 'three'
import Experience from '../Experience.js'

export default class Environment
{
    constructor()
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.debug = this.experience.debug

        // Debug
        if(this.debug.active)
        {
            this.debugFolder = this.debug.ui.addFolder('environment')
            this.addPositioningHelpers()
        }

        this.lightSettings = {
          ambientColor: "#ffffff",
          sunColor: "#ffffff",
        };

        this.ambientLight = new THREE.AmbientLight( this.lightSettings.ambientColor, 1.6 ); // soft white light 0xf3efb6
        this.scene.add( this.ambientLight );
        // console.log(this.ambientLight.color)

        this.setSunLight()

        // this.setEnvironmentMap()
    }

    addPositioningHelpers() {
      // Create two helper objects
      const helper1 = new THREE.Mesh(
        new THREE.BoxGeometry(0.1, 0.1, 0.1),
        new THREE.MeshBasicMaterial({ color: 0x00ff00 })
      );
      const helper2 = new THREE.Mesh(
        new THREE.BoxGeometry(0.3, 0.3, 0.3),
        new THREE.MeshBasicMaterial({ color: 0x0000ff })
      );

      helper1.position.set(1.28, 0, 1.75);
      helper2.position.set(8.1, 1.28, 1.98);

      // Add helpers to the scene
      this.scene.add(helper1);
      this.scene.add(helper2);

      // Debug folder for positioning helpers
      const positionHelpersFolder = this.debug.ui.addFolder('positionHelpers');

      // Position ranges for helpers
      const helper1PositionRange = { x: -1.8, y: -1.8, z: -1.8 };
      const helper2PositionRange = { x: -18, y: -4, z: -18};

      // Add GUI controls for adjusting positions
      positionHelpersFolder
        .add(helper1.position, 'x')
        .name('House Helper X')
        .min(helper1PositionRange.x)
        .max(-helper1PositionRange.x)
        .step(0.001);
      positionHelpersFolder
        .add(helper1.position, 'y')
        .name('House Helper Y')
        .min(helper1PositionRange.y)
        .max(-helper1PositionRange.y)
        .step(0.001);
      positionHelpersFolder
        .add(helper1.position, 'z')
        .name('House Helper Z')
        .min(helper1PositionRange.z)
        .max(-helper1PositionRange.z)
        .step(0.001);

      positionHelpersFolder
        .add(helper2.position, 'x')
        .name('District Helper X')
        .min(helper2PositionRange.x)
        .max(-helper2PositionRange.x)
        .step(0.001);
      positionHelpersFolder
        .add(helper2.position, 'y')
        .name('District Helper Y')
        .min(helper2PositionRange.y)
        .max(-helper2PositionRange.y)
        .step(0.001);
      positionHelpersFolder
        .add(helper2.position, 'z')
        .name('District Helper Z')
        .min(helper2PositionRange.z)
        .max(-helper2PositionRange.z)
        .step(0.001);
    }


    setSunLight()
    {
        this.sunLight = new THREE.DirectionalLight(this.lightSettings.sunColor, 1)
        this.sunLight.castShadow = true
        this.sunLight.shadow.camera.far = 15
        this.sunLight.shadow.mapSize.set(1024, 1024)
        this.sunLight.shadow.normalBias = 0.05
        this.sunLight.position.set(2.0, 3, 1)
        this.scene.add(this.sunLight)


        // Debug
        if(this.debug.active)
        {
            // this.debugFolder
                // .addColor(this.ambientLight, 'color')

            this.debugFolder.addColor(this.lightSettings, 'ambientColor')
              .name('Ambient Color')
              .onChange((value) => {
                const color = new THREE.Color(value);
                this.ambientLight.color = color;
              });

            this.debugFolder
                .add(this.ambientLight, 'intensity')
                .name('ambientLightIntensity')
                .min(0)
                .max(10)
                .step(0.001)

            this.debugFolder.addColor(this.lightSettings, 'sunColor')
                .name('Sun Color')
                .onChange((value) => {
                  const color = new THREE.Color(value);
                  this.sunLight.color = color;
                });

            this.debugFolder
                .add(this.sunLight, 'intensity')
                .name('sunLightIntensity')
                .min(0)
                .max(10)
                .step(0.001)

            this.debugFolder
                .add(this.sunLight.position, 'x')
                .name('sunLightX')
                .min(- 5)
                .max(5)
                .step(0.001)

            this.debugFolder
                .add(this.sunLight.position, 'y')
                .name('sunLightY')
                .min(- 5)
                .max(5)
                .step(0.001)

            this.debugFolder
                .add(this.sunLight.position, 'z')
                .name('sunLightZ')
                .min(- 5)
                .max(5)
                .step(0.001)
        }
    }

    setEnvironmentMap()
    {
        this.environmentMap = {}
        this.environmentMap.intensity = 2.5
        this.environmentMap.texture = this.resources.items.environmentMapTexture
        this.environmentMap.texture.encoding = THREE.sRGBEncoding

        this.scene.environment = this.environmentMap.texture

        this.environmentMap.updateMaterials = () =>
        {
            this.scene.traverse((child) =>
            {
                if(child instanceof THREE.Mesh && child.material instanceof THREE.MeshStandardMaterial)
                {
                    child.material.envMap = this.environmentMap.texture
                    child.material.envMapIntensity = this.environmentMap.intensity
                    child.material.needsUpdate = true
                }
            })
        }
        this.environmentMap.updateMaterials()

        // Debug
        if(this.debug.active)
        {
            this.debugFolder
                .add(this.environmentMap, 'intensity')
                .name('envMapIntensity')
                .min(0)
                .max(4)
                .step(0.001)
                .onChange(this.environmentMap.updateMaterials)
        }
    }
}
