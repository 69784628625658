import * as THREE from 'three'
import gsap from "gsap";
import Experience from '../Experience.js'
import AnimateInOut from '../Utils/AnimateInOut.js'
import UpdateUI from '../Utils/UpdateUI.js'
import PointsOfInterest from '../Utils/PointsOfInterest.js'

export default class Neighborhood
{
    constructor()
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.camera = this.experience.camera
        this.resources = this.experience.resources
        this.time = this.experience.time
        this.debug = this.experience.debug

        this.sceneIsActive = false;


        // Debug
        if(this.debug.active)
        {
            this.debugFolder = this.debug.ui.addFolder('neighborhood')
        }

        // Resource
        this.resource = this.resources.items.neighborhoodModel

        this.setModel()
        // this.animationInOut = new AnimateInOut(this.model, this.resource.animations)
        // this.addAnimationDebug()

        this.uiSettings = {
          slug: "neighborhood",
          title: ['Nachbarschaft', '& öffentlicher', 'Raum'],
          backgroundColor: "#B0D0FF",
          foreground: "dark",
        }
        this.updateUI = new UpdateUI(this.uiSettings)
        this.pointsOfInterest = new PointsOfInterest(this.uiSettings.slug);


        // show scene
        this.camera.on('scene5', () => { this.showScene() })

        //hide scene
        this.camera.on('scene1', () => { this.hideScene() })
        this.camera.on('scene2', () => { this.hideScene() })
        this.camera.on('scene3', () => { this.hideScene() })
        this.camera.on('scene4', () => { this.hideScene() })
        this.camera.on('scene6', () => { this.hideScene() })
        this.camera.on('scene7', () => { this.hideScene() })

    }

    setModel()
    {
      this.model = this.resource.scene
      this.model.scale.set(0.5, 0.5, 0.5)
      this.model.position.set(0, -4, 0)
      this.model.visible = false;
      this.scene.add(this.model)


      // Matcap
      this.matCaps = [ //add colors in order of meshes
        this.resources.items.plasticMatcap, //rutsche
        this.resources.items.holzMatcap, //sand kasten
        this.resources.items.sandMatcap, //sand hügel
        this.resources.items.plasticMatcap, //schaukel sitz
        this.resources.items.metallMatcap, //schaukel kette
        this.resources.items.metallMatcap, //schaukel kette
        this.resources.items.holzMatcap, //schaukel oben
        this.resources.items.holzMatcap, //schaukel holz
        this.resources.items.holzMatcap, //schaukel holz
        this.resources.items.holzMatcap, //schaukel holz
        this.resources.items.holzMatcap, //schaukel holz
        this.resources.items.metallMatcap, //wippe griff
        this.resources.items.metallMatcap, //wippe griff
        this.resources.items.holzMatcap, //wippe
        this.resources.items.holzMatcap, //wippe
        this.resources.items.holzMatcap, //wippe
        this.resources.items.rasenMatcap, //rasen
      ]

      let childIndex = 0;

      this.model.traverse((child) => {
        if (child.isMesh) {
          const matcapTexture = this.matCaps[childIndex];
          matcapTexture.encoding = THREE.sRGBEncoding;
          const material = new THREE.MeshMatcapMaterial({ matcap: matcapTexture, color: 0xffffff });
          // Replace the existing material with the new material
          child.material = material;

          if(childIndex === 0) {
            //hide rutsche
            child.visible = false
          }

          childIndex++
        }
      });

    }

    addAnimationDebug()
    {
        // Debug
        if(this.debug.active)
        {
            const debugObject = {
                // playIn: () => { this.animationInOut.play('in') },
                // playOut: () => { this.animationInOut.play('out') },
            }
            this.debugFolder.add(debugObject, 'playIn')
            this.debugFolder.add(debugObject, 'playOut')
        }
    }

    hideScene() {
      if(this.sceneIsActive == true) {
        this.sceneIsActive = false;
        // this.animationInOut.play('out')
        gsap.to(this.model.position, {
          y: -4,
          duration: 1,
          onComplete: () => {
            this.model.visible = false;
          }
        })
        this.pointsOfInterest.hidePoints()
      }
    }

    showScene() {
      this.sceneIsActive = true;
      // this.animationInOut.play('in')
      gsap.to(this.model.position, {
        y: -1.5,
        duration: 1,
        onStart: () => {
          this.model.visible = true;
        }
      })
      this.pointsOfInterest.showPoints()
      this.updateUI.setActive()
    }

    update()
    {
        // this.animationInOut.update()
        this.pointsOfInterest.update()
    }
}
