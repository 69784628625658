import * as THREE from 'three'
import Experience from '../Experience.js'
import AnimateInOut from '../Utils/AnimateInOut.js'
import UpdateUI from '../Utils/UpdateUI.js'
import PointsOfInterest from '../Utils/PointsOfInterest.js'

export default class Okonomie
{
    constructor()
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.camera = this.experience.camera
        this.resources = this.experience.resources
        this.time = this.experience.time
        this.debug = this.experience.debug

        this.sceneIsActive = false;


        // Debug
        if(this.debug.active)
        {
            this.debugFolder = this.debug.ui.addFolder('okonomie')
        }

        // Resource
        this.resource = this.resources.items.okonomieModel

        this.setModel()
        this.animationInOut = new AnimateInOut(this.model, this.resource.animations)
        this.addAnimationDebug()


        this.uiSettings = {
          slug: "economy",
          title: ['Eigentum &', 'Ökonomie'],
          backgroundColor: "#6E6A7D",
          foreground: "light",
        }
        this.updateUI = new UpdateUI(this.uiSettings)
        this.pointsOfInterest = new PointsOfInterest(this.uiSettings.slug);

        // show scene
        this.camera.on('scene2', () => { this.showScene() })

        //hide scene
        this.camera.on('scene1', () => { this.hideScene() })
        this.camera.on('scene3', () => { this.hideScene() })
        this.camera.on('scene4', () => { this.hideScene() })
        this.camera.on('scene5', () => { this.hideScene() })
        this.camera.on('scene6', () => { this.hideScene() })
        this.camera.on('scene7', () => { this.hideScene() })

    }

    setModel()
    {
        this.model = this.resource.scene
        this.model.scale.set(0.5, 0.5, 0.5)
        this.model.position.set(0, -1.5, 0)
        this.scene.add(this.model)

        // Reset the materials of the loaded GLTF model with a matcap texture
        this.model.traverse((child) => {
          if (child.isMesh) {
            // Create a new material with matcap texture
            const matcapTexture = this.resources.items.greenMatcap;
            matcapTexture.encoding = THREE.sRGBEncoding;
            const material = new THREE.MeshMatcapMaterial({ matcap: matcapTexture });

            // Replace the existing material with the new material
            child.material = material;
          }
        });

        /* //disable shadows for now as they aren't visible that much
        this.model.traverse((child) =>
        {
            if(child instanceof THREE.Mesh)
            {
                child.receiveShadow = true
                child.castShadow = true
            }
        })*/
    }

    addAnimationDebug()
    {
        // Debug
        if(this.debug.active)
        {
            const debugObject = {
                playIn: () => { this.animationInOut.play('in') },
                playOut: () => { this.animationInOut.play('out') },
            }
            this.debugFolder.add(debugObject, 'playIn')
            this.debugFolder.add(debugObject, 'playOut')
        }
    }

    hideScene() {
      if(this.sceneIsActive == true) {
        this.sceneIsActive = false;
        this.animationInOut.play('out')
        this.pointsOfInterest.hidePoints();
      }
    }

    showScene() {
      this.sceneIsActive = true;
      this.animationInOut.play('in')
      this.pointsOfInterest.showPoints();
      this.updateUI.setActive()
    }

    update()
    {
      this.animationInOut.update()
      this.pointsOfInterest.update()
    }
}
