import Swiper, { Navigation, Pagination } from 'swiper';
import 'swiper/swiper.min.css'
import 'swiper/modules/navigation/navigation.min.css'


export default class Gallery {
    init(root) {
      this.root = root
      this.next = root.querySelector('.swiper-button-next')
      this.prev = root.querySelector('.swiper-button-prev')
      this.swiper = new Swiper(this.root, {
        slidesPerView: "auto",
        centeredSlides: true,
        spaceBetween: 30,
        // Responsive breakpoints
        breakpoints: {
          // when window width is >= 320px
          320: {
            spaceBetween: 20
          },
          // when window width is >= 480px
          480: {
            spaceBetween: 30
          },
          // when window width is >= 640px
          640: {
            spaceBetween: 40
          },
          // when window width is >= 640px
          1200: {
            spaceBetween: 120
          },
          1400: {
            spaceBetween: 240
          }
        },
        modules: [Navigation],
        navigation: {
          nextEl: this.next,
          prevEl: this.prev,
        },
      });
      this.root.style.opacity = 1;
    }
    destroy() {
      this.swiper.destroy()
    }
}
