import Experience from '../Experience.js'
import Environment from './Environment.js'
import Floor from './Floor.js'
import House from './House.js'
import Okonomie from './Okonomie.js'
import Mobility from './Mobility.js'
import Energy from './Energy.js'
import District from './District.js'
import Planning from './Planning.js'
import Controlling from './Controlling.js'
import Neighborhood from './Neighborhood.js'

export default class World
{
    constructor()
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources

        // Wait for resources
        this.resources.on('ready', () =>
        {
            // Setup
            // this.fox = new Fox()
            this.floor = new Floor()
            this.house = new House()
            this.okonomie = new Okonomie()
            this.mobility = new Mobility()
            this.energy = new Energy()
            this.district = new District()
            this.planning = new Planning()
            this.controlling = new Controlling()
            this.neighborhood = new Neighborhood()
            this.environment = new Environment()
        })
    }

    update()
    {
      if(this.house)
          this.house.update()
      if(this.okonomie)
          this.okonomie.update()
      if(this.mobility)
        this.mobility.update()
      if(this.energy)
        this.energy.update()
      if(this.controlling)
        this.controlling.update()
      if(this.neighborhood)
        this.neighborhood.update()
      if(this.planning)
        this.planning.update()
      if(this.district)
        this.district.update()
    }
}
