import * as THREE from 'three'
import gsap from "gsap";
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import EventEmitter from './Utils/EventEmitter.js'
import Experience from './Experience.js'

export default class Camera extends EventEmitter
{
    constructor()
    {
        super() //calling the constructor of its parent (the EventEmitter)

        this.experience = new Experience() //Experience is a singelton -> not initialized twice, just a reference
        this.sizes = this.experience.sizes
        this.scene = this.experience.scene
        this.canvas = this.experience.canvas
        this.currentScene = null

        this.minpolar = 1;
        this.maxpolar = Math.PI / 2.1;

        this.twoPi = Math.PI * 2

        this.setInstance()
        this.setControls()
        this.setupZoom()
    }

    setInstance()
    {
        this.instance = new THREE.PerspectiveCamera(35, this.sizes.width / this.sizes.height, 0.1, 100)
        this.instance.position.set(6, 4, 8)
        this.scene.add(this.instance)
    }

    setControls()
    {
      //animateable controls alternative: https://www.npmjs.com/package/camera-controls
      this.controls = new OrbitControls(this.instance, this.canvas)
      this.controls.enableDamping = true
      this.controls.rotateSpeed = 0.8
        // this.controls.autoRotate = true
        // this.controls.autoRotateSpeed = -0.5
      this.controls.enablePan = false
      this.controls.enableZoom = false
      this.controls.minPolarAngle = this.minpolar; // radians
      this.controls.maxPolarAngle = this.maxpolar; // radians
    }

    autoRotate(state, speed) {
      this.controls.autoRotate = state
      this.controls.autoRotateSpeed = speed
    }


    resize()
    {
        this.instance.aspect = this.sizes.width / this.sizes.height
        this.instance.updateProjectionMatrix()
    }

    setupZoom() {
        //Zoom Setup
        this.zoomInPosition = { x: 6, y: 4, z: 8 };
        this.zoomOutPosition = { x: 20, y: 40, z: 20 };

        this.switchViewButton = document.querySelector('.switchview')
        this.switchViewButton.addEventListener('click', () => {
          if(this.switchViewButton.classList.contains('is-neighborhood')) { //"is-neighborhood" meanging the button shows "Quartiersansicht"
            this.playZoomOut()
            this.switchViewButton.classList.remove('is-neighborhood')
            this.switchViewButton.classList.add('is-house')
            //Matomo Tracking
            _paq.push(['trackEvent', 'Navigation', 'Button Quartiersansicht (Switch View)', 'Wechsel von Haus zu Quartier']);
          } else {
            this.switchViewButton.classList.add('is-neighborhood')
            this.switchViewButton.classList.remove('is-house')
            this.playZoomIn()
            //Matomo Tracking
            _paq.push(['trackEvent', 'Navigation', 'Button Quartiersansicht (Switch View)', 'Wechsel von Quartier zu Haus']);
          }
        })
    }

    playZoomOut() {
      gsap.to(this.instance.position, {
        duration: 1,
        x: this.zoomOutPosition.x,
        y: this.zoomOutPosition.y,
        z: this.zoomOutPosition.z
      });
      // this.controls.minPolarAngle = this.minpolar; // radians
      // this.controls.maxPolarAngle = this.minpolar; // radians
    }

    playZoomIn() {
      gsap.to(this.instance.position, {
        duration: 1,
        x: this.zoomInPosition.x,
        y: this.zoomInPosition.y,
        z: this.zoomInPosition.z
      });
      // this.controls.minPolarAngle = this.minpolar; // radians
      // this.controls.maxPolarAngle = this.maxpolar; // radians

    }

    checkSceneAngle() {
      let cameraAngle = this.controls.getAzimuthalAngle() //horizontal angle
      let zoomLevel = this.controls.getDistance()

      if(zoomLevel < 20) {
        //first quarter
        if(cameraAngle > 0 && cameraAngle < (Math.PI * 0.5) && this.currentScene != 1) {
          this.currentScene = 1;
          this.trigger('scene1')
          //Matomo Tracking
          _paq.push(['trackEvent', '3D Interaction', 'Change Perspective', 'Scene 1: Wohnen & Lebenszyklus']);
          //console.log('Wohnen & Lebenszyklus')
        }

        //second quarter
        else if(cameraAngle > (Math.PI * 0.5) && cameraAngle < Math.PI && this.currentScene != 2) {
          this.currentScene = 2;
          this.trigger('scene2')
          //Matomo Tracking
          _paq.push(['trackEvent', '3D Interaction', 'Change Perspective', 'Scene 2: Eigentum & Ökonomie']);
          //console.log('Eigentum & Ökonomie')
        }

        //third quarter
        else if(cameraAngle > -Math.PI && cameraAngle < (-Math.PI * 0.5) && this.currentScene != 3) {
          this.currentScene = 3;
          this.trigger('scene3')
          //Matomo Tracking
          _paq.push(['trackEvent', '3D Interaction', 'Change Perspective', 'Scene 3: Ressourcen & Klima']);
          //console.log('Ressourcen & Klima')
        }

        //fourth quarter
        else if(cameraAngle > (-Math.PI * 0.5) && cameraAngle < 0 && this.currentScene != 4) {
          this.currentScene = 4;
          this.trigger('scene4')
          //Matomo Tracking
          _paq.push(['trackEvent', '3D Interaction', 'Change Perspective', 'Scene 4: Mobilität & Infrastruktur']);
          //console.log('Mobilität & Infrastruktur')
        }
      } else { //zoomed out -> check thirds
        if (cameraAngle >= 0 && cameraAngle <= this.twoPi / 3 && this.currentScene != 5) {
          this.currentScene = 5;
          this.trigger('scene5')
          //Matomo Tracking
          _paq.push(['trackEvent', '3D Interaction', 'Change Perspective', 'Scene 5: Nachbarschaft & öffentlicher Raum']);
          //console.log('Nachbarschaft & öffentlicher Raum')
        } else if (cameraAngle > this.twoPi / 3 && this.currentScene != 6 || cameraAngle < (this.twoPi / -3) && this.currentScene != 6) {
          this.currentScene = 6;
          this.trigger('scene6')
          //Matomo Tracking
          _paq.push(['trackEvent', '3D Interaction', 'Change Perspective', 'Scene 6: Planung & Gestaltung']);
          //console.log('Planung & Gestaltung')
        } else if (cameraAngle >= this.twoPi / -3 && cameraAngle <= 0 && this.currentScene != 7) {
          this.currentScene = 7;
          this.trigger('scene7')
          //Matomo Tracking
          _paq.push(['trackEvent', '3D Interaction', 'Change Perspective', 'Scene 7: Steuerung & Verwaltung']);
          //console.log('Steuerung & Verwaltung')
        }
      }
    }

    update()
    {
        this.controls.update()
        this.checkSceneAngle()
    }
}
